/*-------------------
    Secure Styles
--------------------*/

.dg__secure__inner {
    h2 {
        color: #1a1200;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 32px;
        padding-bottom: 34px;
        position: relative;
        text-transform: uppercase;
        &::before {
            background: #8899b7 none repeat scroll 0 0;
            bottom: 0;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            width: 150px;
        }
    }
    p {
        margin-bottom: 25px;
        padding-right: 14%;
    }
    .secure__btn {
        margin-top: 17px;
    }
}

.dg__secure__transaction {
    padding: 87px 0 60px;
}

.about--2 .dg__secure__inner p {
    color: #051630;
    line-height: 26px;
    padding-right: 16%;
}

.about--2 .dg__secure__inner p+p {
    margin: 0;
}

/* secure--2*/

.secure--2 .dg__secure__inner h2 {
    color: #1750b0;
    line-height: 35px;
    padding-right: 19%;
}

.dg__secure__thumb {
    position: relative;
    text-align: right;
    >img {
        position: relative;
        z-index: 2;
    }
    .shape-2 {
        bottom: -6px;
        left: -71px;
        position: absolute;
    }
}



/* Responsive Css*/

@media #{$xlg-layout} {
    .dg__secure__thumb>img {
        width: 100%;
    }
    .dg__secure__transaction {
        padding: 106px 0 120px;
    }
}

@media #{$lg-layout} {
    .dg__secure__thumb img {
        width: 100%;
    }
    .dg__secure__transaction {
        padding: 130px 0 140px;
    }
    .about--2 .dg__secure__inner p {
        padding-right: 9%;
    }
}

@media #{$md-layout} {
    .dg__secure__thumb img {
        width: 100%;
    }
    .wallet__thumb img {
        width: 100%;
    }
    .dg__secure__transaction {
        padding: 130px 0 50px;
    }
}

@media #{$sm-layout} {
    .dg__secure__thumb img {
        width: 100%;
    }
    .about--2 .dg__secure__inner p {
        padding-right: 0;
    }
    .wallet__thumb img {
        width: 100%;
    }
    .dg__secure__transaction {
        padding: 130px 0 69px;
    }
    .secure--2 .dg__secure__inner h2 {
        font-size: 23px;
        padding-right: 0;
    }
    .dg__secure__transaction {
        padding: 60px 0 30px;
    }
    .dg__secure__inner h2 {
        font-size: 18px;
        line-height: 34px;
    }
    .about__dgtaka {
        padding: 60px 0;
    }

}

@media #{$xs-layout} {
    .dg__secure__inner p {
        padding-right: 0;
    }
}

@media #{$xx-layout} {
    .dg__secure__thumb>img {
        width: 100%;
    }
}