/* external styles */
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/animate.css/animate.min.css";
//@import "../../../node_modules/react-modal-video/scss/modal-video.scss";
//@import "../../../node_modules/swiper/css/swiper.css";

/* Needed Files*/
@import "variables";
@import "mixins";

/*******************
Basic Styles
********************/
@import "basic/reset";
@import "basic/typography";
@import "basic/helper-class";

/*******************
Elements Styles
********************/
@import "elements/button";
@import "elements/section-title";

/*******************
Blocks Styles
********************/
@import "blocks/header";
@import "blocks/navigation";
@import "blocks/banner";
@import "blocks/slider";
@import "blocks/tickets";
@import "blocks/service";
@import "blocks/calculation";
@import "blocks/counterup";
@import "blocks/sucure";
@import "blocks/chart";
@import "blocks/software";
@import "blocks/blog";
@import "blocks/brand";
@import "blocks/newsletter";
@import "blocks/work";
@import "blocks/convert";
@import "blocks/breadcrumb";
@import "blocks/video";
@import "blocks/merchants";
@import "blocks/sidebar";
@import "blocks/blog-details";
@import "blocks/team";
@import "blocks/single-service";
@import "blocks/feature";
@import "blocks/currency-table";
@import "blocks/contact";
@import "blocks/bitcoin";
@import "blocks/login";
@import "blocks/footer";
@import "blocks/not-found";

//Keyword Table

.keyword-table {
  width: 100% !important ;
  font-size: 1rem;
}
.table-head {
  background-color: #01123d;
  color: white;
  margin-top: 0px;
  font-weight: bold;
  height: 30px;
  text-align: center;
  align-items: center;
}
.table-head-parts {
  align-items: center;
  text-align: center;
  display: table-cell;
  font-size: 1rem;
}
.table-row {
  background-color: #01123d;
  font-size: 1rem;
  font-weight: bold;

  text-align: left;
  align-items: center;
}
.table-hide {
  margin-top: 10px;
  margin-bottom: 10px;
}
.table-head-chart {
  text-align: center;
}
.table-sub-head {
  font-weight: bold !important;
  font-size: 1rem !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
}
.keyword-select-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
}
// .highlightedAsin {
//   background: #cc012e;
//   border-radius: 5px;
//   padding: 7px
// }
.goToKeyword {
  color: white !important;
  text-decoration: none !important;
}
.goToKeyword:hover {
  color: white !important;
  text-decoration: none !important;
}
.goToKeyword:visited {
  color: white !important;
}
.goTo {
  // border: 1px solid #007aff !important;
  // border-radius: 5px !important;
  // padding: 7px !important;
  color: #000e32 !important;
  // background-color: #007aff !important;
  text-decoration: none !important;
}
.goTo:hover {
  color: #000e32 !important;
  text-decoration: none !important;
}
.goTo:visited {
  color: #000e32 !important;
}
.marketTableRow {
  width: 100% !important;
}
// LOGIN AND REGISTER PAGES //
.loginPage {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  //position: fixed;
  top: 0;
  left: 0;
  background-image: url("../images/7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.page {
  width: 30%;
  height: 100%;
  margin: 0 auto;
  padding-top: 175px;
  position: relative;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1rem;
  line-height: normal !important;
  font-weight: bold;
}
.opacity {
  color: white;
  line-height: normal;
  display: block;
}
.loginInput {
  min-height: 32px !important;
  height: 40px !important;
  border-radius: 5px !important;
}
.loginInput:focus {
  border-color: #007bff !important;
}
.register {
  background-color: #011133 !important;
  border: 1px solid #007bff !important;
  border-radius: 5px !important;
  line-height: normal !important;
  padding: 5px !important;
  width: 100% !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  height: 40px;
}
.register:hover {
  background-color: white !important;
  color: #007bff !important;
}
.underline {
  color: white !important;
}
.underline:hover {
  text-decoration: none;
}
.keyWordIcon {
  display: none !important;
  font-size: 2rem !important;
  cursor: pointer;
}

@media only screen and (max-width: 893px) {
  .page {
    width: 80%;
    padding-top: 25px;
    height: 50%;
  }
  .none {
    display: none !important;
  }
  .home {
    height: 100% !important;
  }
  .formMedia {
    padding: 0 !important;
    margin: 0 !important;
  }
  .keyWordIcon {
    display: block !important;
  }
  .keyWordForm {
    display: none !important;
  }
}

//ASIN LİSTE
.preNextBtns {
  margin-bottom: 1rem;
}
.preNextBtn {
  background-color: #007bff !important ;
  color: white !important;
  border-radius: 5px !important;
}
.asinLinkTable {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.cursor {
  cursor: pointer;
}
.icons {
  font-size: large !important;
}

//Asin Analiz
.click-bar {
  border: 20px solid #553d67;
  border-top: 1px solid #553d67;
  border-bottom: 1px solid #553d67;
}
.conversion-bar {
  border: 20px solid #99738e;
  border-top: 1px solid #99738e;
  border-bottom: 1px solid #99738e;
}
.bg-click {
  background-color: #553d67;
}
.bg-conversion {
  background-color: #99738e;
}
.red {
  color: red;
}
.innerTableBtn {
  border: 1px solid white;
  border-radius: 5px;
  background-color: #011e4f !important;
}
.innerTableBtn:hover {
  background-color: white !important;
  color: blue !important;
}
.tabContect {
  width: 95% !important;
}
.term {
  border: 1px solid;
  border-radius: 5px;
  font-size: medium;
  margin-bottom: 3px;
  min-height: 5rem;
}
//Favori Kelime

@keyframes example {
  from {
    display: none;
  }
  to {
    display: block;
  }
}

//ACCOUNT //
.account-nav-button {
  font-size: 2rem !important;
  color: black;
}
.iconMargin {
  margin-top: 2rem;
  margin-bottom: 12rem;
}
//ABOUT US
.aboutusone {
  background-image: url("../images/9.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

//FOOTER
.footer_links:hover {
  text-decoration: none !important;
}
.pagi {
  color: white !important;
  background-color: #00113c !important;
}

//çalışma

.filtreler {
  background-color: white;
  box-shadow: 3px 3px 10px #aaaaaa;
  border-radius: 10px;
}

// payment cards
.advanced {
  opacity: 0.5;
}

// tooltip
acronym {
  text-decoration: none;
}

#cvc {
  min-width: 0px !important;
}

//Bsr table
#bsr-table {
  display: inline-table !important;
}

.pricing-table-button-monthly {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  min-width: 200px;
  background: transparent;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.pricing-table-button-monthly:hover {
  background: rgba($color: #011949, $alpha: 0.1);
  color: #011949;
  border: #011949 1px solid;
}
.pricing-table-button-monthly-selected {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  min-width: 200px;
  background: #011949;
  color: white;
  outline: #011949;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.pricing-table-button-monthly-selected:hover {
  background: rgba($color: #011949, $alpha: 0.9);
  outline-offset: 5px;
}
.pricing-plan-main {
  text-align: center;
  background: white;
}
.pricing-cuppon-input {
  margin-bottom: 10px;
}
.pricing-cuppon-button {
  padding: 10px;
  background: transparent;
  background-color: #011949;
  border-radius: 10px;
  color: white;
  width: 100px;
  height: 60px;
}
.pricing-cuppon-button:hover {
  background: transparent;
  color: #011949;
  border: 1px solid #011949;
}
.pricing-table-button-yearly {
  padding: 16px 30px;
  font-size: 16px;
  min-width: 200px;
  background: transparent;
  border-left: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.pricing-table-button-yearly:hover {
  background: rgba($color: #011949, $alpha: 0.1);
  color: #011949;
  border: #011949 1px solid;
}
.pricing-table-button-yearly-selected {
  padding: 16px 30px;
  font-size: 16px;
  min-width: 200px;
  background: #011949;
  color: white;
  border-left: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  outline: #011949;
}
.pricing-table-button-yearly-selected:hover {
  background: rgba($color: #011949, $alpha: 0.9);
}
.pricing-table {
  margin-bottom: 20px;
}

.pricing-basic-button {
  padding: 16px 30px;
  font-size: 16px;
  background: transparent;
  border-radius: 10px;
}
.pricing-basic-button:hover {
  background: rgba(128, 128, 128, 0.1);
}

.pricing-suite-button {
  padding: 16px 30px;
  font-size: 16px;
  background: #011949;
  color: #ffffff;
  border-radius: 10px;
}
.pricing-suite-button:hover {
  box-shadow: 2.5px 5px 22px gray;
}

.pricing-professional-button {
  padding: 16px 30px;
  font-size: 16px;
  background: transparent;
  border-radius: 10px;
}
.pricing-professional-button:hover {
  background: rgba(128, 128, 128, 0.1);
}
.register-new {
  background: white;
}

#payment-login-main {
  margin-top: 20px;
  background: white;
  min-width: 80%;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 16px;
}
#circle-big {
  font-size: 50px;
  color: #011949;
}
#circle-small {
  font-size: 30px;
}
#package-desc {
  margin-top: 20px;
  background: white;
  min-width: 80%;
  padding-bottom: 20px;
  padding-top: 20px;
}
#circle-small-colored {
  color: #011949;
  font-size: 30px;
}
.modal-full {
  min-width: 90%;
}

#hesabim-button {
  background-color: #011949;
  color: white;
}

#hesabim-button-clicked {
  background-color: #4fc3f7;
  color: #011949;
  box-shadow: 5px 10px 18px grey;
}

.red-text-color {
  color: red;
}

//OutFit
.single-line {
  overflow: auto;
  height: 150px;
  border: 1px solid #011133;
  border-radius: 8px;
  padding: 5px;
}
.dropdown-outfit {
  color: white;
}

#pagination-list li {
  border: 1px solid black;
  font-size: 1.4em;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}
.pagination {
  background-color: transparent;
}
.pagination-selected {
  background-color: rgb(1, 17, 51);
  color: white;
}
.outfit-cards {
  border: 0.5px solid #011133;
  border-radius: 11px;
  box-shadow: 3px 3px 10px #aaaaaa;
}

// Cargo Calc

@keyframes display {
  from {
    bottom: 280px;
  }
  to {
    bottom: 6%;
  }
}
.box-product-inputs {
  background-color: white;
  box-shadow: 4px 4px 10px #aaaaaa;
  border-radius: 10px;
  padding-bottom: 30px;
}
.box-add {
  background-color: white;
  box-shadow: 4px 4px 10px #aaaaaa;
  border-radius: 10px;
  margin-top: 30px;
}
.inner-box {
  position: absolute;
  bottom: 6%;
  left: 4%;
  width: 30%;
  animation: display 4s normal;
}
.big-box {
  position: relative;
  top: 0;
  left: 0;
}
.background-div {
  position: relative;
  top: 0;
  left: 0;
}
.big-box-x {
  font-size: 0.9rem;
  position: absolute;
  bottom: -7%;
  left: 35%;
  font-weight: 600;
}
@media only screen and (max-width: 992px) {
  .big-box-x {
    bottom: 1%;
  }
}

.big-box-y {
  font-size: 0.9rem;
  position: absolute;
  bottom: 9%;
  left: 88%;
  font-weight: 600;
}
.big-box-z {
  font-size: 0.9rem;
  position: absolute;
  top: 35%;
  right: 9%;
  font-weight: 600;
}
.cargo-tabs {
  padding: 0px !important;
}
.activepage {
  background-color: rgb(85, 61, 103) !important;
  color: white !important;
}
.product-info-list {
  list-style-type: circle;
  // display: none;
}

// ----------Order Summary---------

.order-summary-price {
  float: right;
}
.order-summary-title {
  color: black !important;
}
.countdown-background {
  background-image: url("../images/7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1000px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
}
.countdown-image {
  width: 50%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.e-training-card {
  margin: auto;
  margin-top: 30px;
}
.iframe-div {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
}
.options-div {
  height: 550px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 3%;
  overflow-y: scroll;
  border: 1px solid skyblue;
  float: right;
  border-radius: 5px;
}

.course-single {
  border-bottom: 1px solid black;
  padding: 15px 5px 0px 8px;
  cursor: pointer;
}
.course-single-clicked {
  border-bottom: 1px solid black;
  padding: 15px 5px 0px 8px;
  cursor: pointer;
  background: #011133;
}
.course-single-p {
  font-size: 1.2rem;
  color: #011133;
}
.course-single-p-clicked {
  font-size: 1.2rem;
  color: white;
}

.btn-google {
  text-transform: none;
  width: 100%;
  border-radius: 6px !important;
  margin-bottom: 10px;
  height: 40px !important;
  background-color: #011133 !important;
  border: 2px solid #007bff !important;
  color: #fff !important;
}

.btn-google:hover {
  background-color: #fff !important;
  color: #007bff !important;
  font-weight: 300 !important;
}

.icon-google {
  margin-bottom: 3px;
  margin-right: 5px;
}

.cell-visibility {
  display: none;
}

.stats-lines {
  color: #007bff;
}
.stats-lines:hover {
  cursor: pointer;
}
.hot_keywords_input {
  min-height: 32px !important;
  height: 42px !important;
  border-radius: 5px !important;
}

.hot_keywords_input:focus {
  border-color: #007bff !important;
}

.hot {
  background-color: #011133 !important;
  border: 1px solid #007bff !important;
  border-radius: 5px !important;
  line-height: normal !important;
  padding: 5px !important;
  width: 100% !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  height: 44px;
}
.hot:hover {
  background-color: white !important;
  color: #007bff !important;
}

// #blog-head{
//   background-image: url("../images/blog.jpg");
//   background-repeat: no-repeat;
//   background-size: contain;
//   height:800px;
//   text-align: center;
//   overflow-x: hidden;
//   overflow-y: hidden;
//   margin: 0;
//   padding: 0;
// }
#blog-search {
  position: relative;
  top: -200px;
  margin-right: 0px;
  padding-right: 0px;
  //  max-width: 550px;
  width: 25%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-right: none;
}
.blog-search-button {
  position: relative;
  top: -197px;
  height: 100%;
  padding-top: 0px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-left: none;
  background: white;
  min-height: 52px;
  border: none;
  height: auto;
  border-color: white;
}
.search-icon-blog {
  font-size: 1.4rem;
  padding-right: 10px;
  color: #011133;
}
#jumbo-title {
  position: relative;
  top: -340px;
  font-family: "Courier New", Courier, monospace;
  color: white;
}

//---------Blog Cards--------------

#read-more {
  color: white;
}
#read-more:hover {
  color: black;
}
.blog-footer-text {
  margin-left: 50px;
}
#blog-footer {
  margin-left: 60px;
  margin-top: 60px;
  margin-bottom: 60px;
}
#writer-image {
  border-radius: 50%;
  max-width: 70px;
  float: left;
  margin-right: 30px;
}
#single-blog-image {
  max-height: 400px;
  width: 30%;
  border-radius: 20px;

  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 5%;
}
#blog-title {
  color: black;
  display: inline;
  margin-top: 30px;
  // margin-left: 30px;
  padding-top: 30px;
}
#essay {
  //float: left;
  // display: inline-block;
  margin-left: 30px;
  padding-left: 30px;
}
.font-blog-cat {
  font-size: 1.2rem;
  cursor: pointer;
}

// #blog-card-footer{

// }
#blog-card-date {
  float: right;
  margin-right: 10px;
  color: gray;
}
.containerblog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 20px 0 80px;
}

.cardblog {
  width: 45%;
  margin: 10px;
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 20px;
}
.cardblog:hover .card__caption {
  top: 50%;
  transform: translateY(-50%);
}
.cardblog:hover .card__image {
  transform: translateY(-10px);
}
.cardblog:hover .card__thumb::after {
  top: 0;
}
.cardblog:hover .card__snippet {
  margin: 20px 0;
}
.card__thumb {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .card__thumb {
    max-height: 450px;
  }
}
.card__thumb::after {
  position: absolute;
  top: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s;
}
@media (min-width: 1024px) {
  .card__thumb::after {
    top: calc(100% - 140px);
  }
}
.card__image {
  transition: 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.card__caption {
  position: absolute;
  top: 50%;
  z-index: 1;
  padding: 0 20px;
  color: white;
  transform: translateY(-50%);
  text-align: center;
  transition: 0.3s;
}
@media (min-width: 1024px) {
  .card__caption {
    top: calc(100% - 100px);
    transform: unset;
  }
}
.card__title {
  display: -webkit-box;
  max-height: 85px;
  overflow: hidden;
  font-family: "Playfair Display", serif;
  font-size: 23px;
  line-height: 28px;
  text-shadow: 0px 1px 5px black;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: white;
}
.card__snippet {
  display: -webkit-box;
  max-height: 150px;
  margin: 20px 0;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  transition: 0.5s ease-in-out;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: white;
}
@media (min-width: 1024px) {
  .card__snippet {
    margin: 60px 0;
  }
}
.card__button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  border: 1px solid white;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
}
.card__button:hover {
  color: black;
  background-color: white;
}

.disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
  background-color: white;
  transform: translateX(-50%);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
}
.disclaimer__link {
  color: #755d87;
  text-decoration: none;
}

// payment successful page
.success-card {
  background: white;
  padding: 80px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

.checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.success-word {
  color: #88b04b;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.success-detail {
  color: #404f5e;
  font-size: 20px;
  margin: 0;
}
//Etsy Keyword Parts

.etsy-count-card,
.etsy-average-card {
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.etsy-count-card {
  // height: 8rem;
  // background-color: #fdebd2 !important;
  background-color: white !important;
  color: black;
  // color: black;
}
.etsy-average-card {
  background-color: #f48a56 !important;
  color: #ffffff !important;
}
.etsy-average-header {
  color: white;
}
.etsy-count-value {
  font-size: 1.5rem;
  font-weight: lighter !important;
}
.etsy-count-header {
  color: black !important;
}
.etsy-average-value {
  font-size: 1.5rem;
}

.wordcloud-popcategory {
  display: flex;
}
.word-cloud {
  height: 25rem;
  border-radius: 15px !important;
  // background-color: orange;

  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popular-categories {
  border-radius: 15px !important;
  height: 25rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.etsy-price-range,
.etsy-processing-time {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 25rem;
  border-radius: 15px !important;
}
.graphicEtsy {
  // background-color: red;
  width: 20rem;
  justify-content: center;
  height: 20rem;
  margin: auto;
}
.etsy-bckg {
  // background-color: #fdebd2 !important;
  background-color: white !important;
}
.etsy-data-table {
  background-color: white;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.etsy-table-headers {
  background-color: "#011133" !important;
  color: "white" !important;
  font-size: "1rem" !important;
}
.etsy-category {
  font-size: 1.2rem !important;
}
.etsy-listing-img img {
  transition: transform 0.2s;
}

.etsy-listing-img img:hover {
  transform: scale(2);
}
.etsy_keywords_input {
  min-height: 42px !important;
  height: 52px !important;
  border-radius: 5px !important;
}

.etsy_keywords_input:focus {
  border-color: #007bff !important;
}

.etsy-btn {
  background-color: #011133 !important;
  border: 1px solid #007bff !important;
  border-radius: 5px !important;
  line-height: normal !important;
  padding: 5px !important;
  width: 100% !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  height: 52px;
}
.etsy-btn:hover {
  background-color: #d5641c !important;
  color: white !important;
}


.subCards{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.subCard{
  min-width: 22vw !important;
  height: 14vw !important;
  text-align: center;
  padding: 0.2vw !important;
}

.subCardItem{
font-size: 1.1vw;
}

.subsDoughnutGraph{
  width: 17vw;
  text-align: center;
  font-size: 1.1vw;
  // height: 38vw;
}


.subGraphWrapper{
  width: 90%;
  margin: auto;
}

.doughnut{
  display: flex;
  justify-content: space-between;
}

.doughnutTitles{
  height:12vw ;
}
.doughnutTitles h3, h5{
  font-size: 1vw;
}