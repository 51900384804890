/*-------------------
    Software Styles
--------------------*/

.phone__images {
    text-align: center;
}

.dg__software__inner {
    h2 {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 36px;
        padding-right: 8%;
    }
    p {
        margin-bottom: 10px;
    }
    .avilable__device {
        display: flex;
        flex-wrap: wrap;
        list-style: outside none none;
        padding: 0;
        li {
            flex-basis: 50%;
            margin-top: 40px;
        }
    }
}

.dg__software--2 {
    .dg__software__inner {
        h2 {
            color: #e6e6e6;
        }
        p {
            color: #e6e6e6;
        }
    }
}

.section-padding--tb {
    padding: 140px 0 130px;
}

/* Responsive Css*/

@media #{$lg-layout} {}

@media #{$md-layout} {
    .dg__software__inner h2 {
        font-size: 33px;
        margin-bottom: 36px;
    }



}

@media #{$sm-layout} {
    .dg__software__inner h2 {
        font-size: 26px;
        margin-bottom: 20px;
        padding-right: 0;
        line-height: 36px;
    }


}

@media #{$xs-layout} {
    .dg__software__inner .avilable__device li a img {
        width: 100%;
    }
    .dg__software__inner .avilable__device li {
        padding: 0 10px;
    }
    .dg__software__inner .avilable__device {
        margin-left: -10px;
        margin-right: -10px;
    }
}

@media #{$xxs-layout} {}