/*-------------------
    Contact Styles
--------------------*/

.contact__title {
    text-align: center;
    h2 {
        font-size: 36px;

        margin-top: 48px;

        color: #1752B4;
    }
    p {
        padding: 0 20%;
    }
}

.conact__area {
    .container-fluid {
        margin-top: 120px;
        margin-right: -15px;
        margin-left: -15px;

        background: #FAFAFA none repeat scroll 0 0;
    }
}

.single-contact-form {
    margin-bottom: 22px;
    span {
        font-weight: 700;

        display: block;

        margin-bottom: 20px;

        text-transform: uppercase;

        color: #03193D;
    }
    input {
        width: 450px;
        height: 45px;
        padding: 0 20px;

        border: 1px solid #97B4E5;
        border-radius: 5px;
        outline: medium none;
    }
    textarea {
        width: 450px;
        min-height: 115px;
        padding: 10px;

        border: 1px solid #97B4E5;
        border-radius: 5px;
        outline: medium none;
    }
}

.contact-btn {
    margin-top: 30px;
    button {
        height: 40px;
        padding: 0 40px;

        transition: 0.5s;
        text-transform: uppercase;

        color: #FFF;
        border: 0 none;
        border-radius: 5px;
        background: #1752B4 none repeat scroll 0 0;
        &:hover {
            background: #F7A901 none repeat scroll 0 0;
        }
    }
}

.dg__contact__wrapper {
    padding-right: 70px;
    padding-left: 70px;
}

.google__map {
    position: relative;

    width: 100%;
    height: 750px;
}

/* Responsive Css*/

@media #{$lg-layout} {
    .contact__title p {
        padding: 0 13%;
    }
    .dg__contact__wrapper {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media #{$md-layout} {
    .dg__contact__wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .contact__title img {
        width: 100%;
    }
    .google__map {
        margin-right: -15px;
    }
    .google__map {
        margin-right: -30px;
    }
    .contact__title p {
        padding: 0 8%;
    }
    .google__map {
        height: 500px;
        margin-right: 0;
    }
    .single-contact-form input {
        width: 100%;
    }
    .single-contact-form textarea {
        width: 100%;
    }
    .conact__area .container-fluid {
        margin-right: 0;
        margin-left: 0;
    }
    .conact__area .container-fluid {
        margin-top: 44px;
    }
}

@media #{$sm-layout} {
    .contact__title img {
        width: 100%;
    }
    .contact__title p {
        padding: 0;
    }
    .google__map {
        height: 300px;
        margin-right: -15px;
    }
    .dg__contact__wrapper {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .dg__contact__wrapper {
        padding-right: 20px;
        padding-left: 20px;
    }
    .google__map {
        margin-right: 0;
    }
    .single-contact-form input {
        width: 100%;
    }
    .single-contact-form textarea {
        width: 100%;
    }
    .conact__area .container-fluid {
        margin-right: 0;
        margin-left: 0;
    }
    .conact__area .container-fluid {
        margin-top: 44px;
    }
    .conact__area {
        padding-top: 60px;
    }
    #googleMap {
        height: 400px;
    }
    .contact__title h2 {
        font-size: 24px;

        margin-bottom: 19px;
    }
}
