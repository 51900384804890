/*-------------------
    Chart Styles
--------------------*/

.live__cart__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    h2 {
        color: #e6e9ef;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
    }
    .currency__date {
        display: flex;
        margin-right: -12px;
        .single__currency {
            margin: 0 12px;
            span {
                color: #ffffff;
                font-size: 16px;
                padding-right: 13px;
                text-transform: uppercase;
            }
            select {
                background: transparent none repeat scroll 0 0;
                border: 1px solid #24211b;
                color: #fff;
                height: 30px;
                padding: 0 10px;
            }
        }
    }
}

.chart-space.section-padding--xlg {
    padding: 132px 0 140px;
}

.nice-select::after {
    border-bottom: 2px solid #9fabc1;
    border-right: 2px solid #9fabc1;
    height: 8px;
    margin-top: -5px;
    right: 17px;
    width: 8px;
}

.live__cart__header .currency__date .single__currency .nice-select span {
    color: #425473;
}

.single__currency .nice-select {
    background-color: transparent;
    border: 1px solid #1a2a47;
    border-radius: 5px;
    float: right;
    font-size: 16px;
    height: 30px;
    line-height: 29px;
    padding-left: 14px;
    padding-right: 25px;
}

.single__currency .nice-select .list {
    width: 100%;
}

.live__cart__header .currency__date .single__currency {
    align-items: center;
    display: flex;
}

/* Responsive Css*/

@media #{$lg-layout} {
    .chart__img img {
        width: 100%;
    }
}

@media #{$md-layout} {
    .chart__img img {
        width: 100%;
    }
    .live__cart__header {
        display: block;
    }
}

@media #{$sm-layout} {
    .chart__img img {
        width: 100%;
    }
    .live__cart__header {
        display: block;
    }
    .dg__live__chart {
        padding: 60px 0;
    }
}

@media #{$xs-layout} {
    .live__cart__header .currency__date .single__currency span {
        padding-right: 8px;
    }
}

@media #{$xxs-layout} {
    .live__cart__header .currency__date {
        display: block;
    }
    .live__cart__header .currency__date .single__currency+.single__currency {
        margin-top: 20px;
    }
}

@media #{$xlg-layout} {
    .chart__img img {
        width: 100%;
    }
}